import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import type { SkeletonProps } from '@mui/material/Skeleton';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { FILES, GEOSCIENCE_OBJECTS, RECYCLE_BIN_TITLE } from 'src/strings';

const PATH_TITLE_MAP: { [key: string]: string } = {
    files: FILES,
    overview: GEOSCIENCE_OBJECTS,
    recyclebin: RECYCLE_BIN_TITLE,
};

function getPageTitle(pathname: string) {
    const segment = pathname.slice(pathname.lastIndexOf('/') + 1, pathname.length);
    return PATH_TITLE_MAP[segment] || segment;
}

interface SkeletonContentProps extends React.PropsWithChildren<SkeletonProps> {
    loading: boolean;
}

function SkeletonContent({ children, loading, ...skelton }: SkeletonContentProps) {
    if (loading) {
        return <Skeleton {...skelton} />;
    }
    return children;
}

export interface PageContentProps {
    pageTitle: string | JSX.Element;
    pageTitleLoading?: boolean;
    pageSubTitle?: string | JSX.Element;
    titleActions?: JSX.Element;
    children: React.ReactNode;
    childrenLoading?: boolean;
    childrenLoadingContent?: JSX.Element;
    enablePageTitle?: boolean;
}

export const PageContent = ({
    children,
    pageTitle,
    pageTitleLoading = false,
    pageSubTitle,
    titleActions,
    childrenLoading = false,
    childrenLoadingContent,
    enablePageTitle = true,
}: PageContentProps) => {
    const location = useLocation();

    return (
        <>
            <SkeletonContent
                width="50%"
                height="36px"
                variant="rectangular"
                loading={pageTitleLoading}
            />
            <CardHeader
                title={pageTitle}
                titleTypographyProps={{
                    component: 'h1',
                    variant: 'h3',
                    'automation-id': 'title-row',
                }}
                subheaderTypographyProps={{ variant: 'h5', textTransform: 'capitalize' }}
                subheader={pageSubTitle || (enablePageTitle && getPageTitle(location.pathname))}
                action={titleActions}
                sx={{
                    padding: '0 0 16px 0',
                    flexWrap: 'wrap',
                    '.MuiCardHeader-content': {
                        flexGrow: 1,
                        flexShrink: 0,
                    },
                    whiteSpace: 'pre',
                }}
            />
            <Grid container direction="column">
                {childrenLoading && childrenLoadingContent ? childrenLoadingContent : children}
            </Grid>
        </>
    );
};
